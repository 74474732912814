import React from 'react';
import { alignTextSvg } from 'utils/utils';

const TextInSvg = ({ shape }) => {
  const { x, textAnchor } = alignTextSvg(shape.textAlign, shape.width);
  return (
    <text
      fontSize={`${shape.fontSize}`}
      fontFamily={shape.fontFamily}
      fontWeight={shape.isBold ? 'bold' : 'normal'}
      fontStyle={shape.isItalic ? 'italic' : 'normal'}
      fill={shape.fill || '#000000'}
      transform={`translate(${shape.x},${shape.y}) rotate(${shape.rotation})`}
      dominantBaseline="hanging"
    >
      {shape.textArr?.length ? (
        shape.textArr.map((text, index) => (
          <tspan y={`${index * shape.fontSize}px`} x={x} textAnchor={textAnchor} dominantBaseline="hanging">
            {text || ''}
          </tspan>
        ))
      ) : (
        <tspan x={x} textAnchor={textAnchor} dominantBaseline="hanging">
          {shape.renderText || shape.text}
        </tspan>
      )}
    </text>
  );
};

export default TextInSvg;
